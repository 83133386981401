<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSnEq"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSnEq"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhoneEq"
                    label="收件人电话"
                >
                    <el-input
                        v-model="queryFormModel.deliveryPhoneEq"
                        placeholder="请输入收件人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerMobileEq"
                    label="下单人电话"
                >
                    <el-input
                        v-model="queryFormModel.buyerMobileEq"
                        placeholder="请输入下单人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="trackingNumberEq"
                    label="快递单号"
                >
                    <el-input
                        v-model="queryFormModel.trackingNumberEq"
                        placeholder="请输入快递单号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!--订单列表-->
            <el-table
                class="order-table"
                ref="table"
                stripe
                border
                size="small"
                :data="tableData"
                :span-method="onSpanMethod"
                style="width: 100%"
            >
                <el-table-column
                    class-name="cell-no-pa"
                    label="商品"
                >
                    <template slot-scope="order">
                        <div class="order-header">
                            <div>
                                <span>下单时间：{{ order.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}</span>
                                <span>订单号：{{ order.row.orderSn }}</span>
                            </div>
                            <div
                                class="supplier"
                                v-if="order.row.supplierName">
                                供应商：{{ order.row.supplierName }}
                            </div>
                            <div class="shipping-time">
                                <template v-if="order.row.estimateShippingTime">
                                    预计发货时间：{{ order.row.estimateShippingTime | moment }}&nbsp;&nbsp;
                                </template>
                                <template v-if="order.row.shippingTime">
                                    发货时间：{{ order.row.shippingTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </div>
                        </div>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                            :show-header="false"
                            :span-method="onGoodsSpanMethod"
                        >
                            <el-table-column label="商品">
                                <div
                                    class="goods-wrap"
                                    slot-scope="scope"
                                >
                                    <ImageList :data="scope.row.specImageUrl"/>
                                    <div class="body">
                                        <div class="label">
                                            {{ scope.row.goodsName }}
                                        </div>
                                        <div class="spec">
                                            {{ scope.row.specDesc }}
                                        </div>
                                        <div
                                            class="badge-wrap"
                                        >
                                            <div
                                                class="badge"
                                                v-if="order.row.isPresellOrder"
                                            >
                                                预
                                            </div>
                                            <div
                                                class="badge"
                                                v-if="scope.row.goodsPremiumAmount"
                                            >
                                                保
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-table-column>
                            <el-table-column
                                prop="finalPrice"
                                label="单价"
                                width="100"
                            />
                            <el-table-column
                                prop="quantity"
                                label="数量"
                                width="100"
                            />
                            <el-table-column
                                label="订单金额"
                                width="100"
                            >
                                <el-popover
                                    trigger="hover"
                                >
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.amountInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="label"
                                        />
                                        <el-table-column
                                            width="100px"
                                            property="value"
                                        />
                                    </el-table>
                                    <span
                                        slot="reference"
                                        class="tc-blue"
                                    >{{ order.row.payableAmount }}</span>
                                </el-popover>
                            </el-table-column>
                            <el-table-column
                                prop="orderStatusName"
                                label="订单状态"
                                width="100"
                            >
                                {{ order.row.orderStatusName }}
                            </el-table-column>
                            <el-table-column
                                label="物流信息"
                                width="300"
                            >
                                <el-popover
                                    trigger="hover"
                                >
                                    <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                                        <div class="w-50per">
                                            物流公司:{{ order.row.expressName }}
                                        </div>
                                        <div class="w-50per">
                                            物流单号:{{ order.row.trackingNumber }}
                                        </div>
                                    </div>
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.trackingInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="ftime"
                                        />
                                        <el-table-column
                                            width="600px"
                                            property="context"
                                        />
                                    </el-table>
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.clearanceTrackingInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="ftime"
                                        />
                                        <el-table-column
                                            width="600px"
                                            property="context"
                                        />
                                    </el-table>
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.internationalTrackingInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="ftime"
                                        />
                                        <el-table-column
                                            width="600px"
                                            property="context"
                                        />
                                    </el-table>
                                    <div
                                        slot="reference"
                                        style="height: 100px"
                                        class="tc-blue"
                                    >
                                        <div v-if="order.row.trackingInfoList && order.row.trackingInfoList.length !== 0">
                                            <div
                                                class="dp-fx fx-jc-sb fx-ai-c"
                                                v-if="order.row.expressName"
                                            >
                                                <div class="w-50per">
                                                    物流公司:{{ order.row.expressName }}
                                                </div>
                                                <div class="ta-r w-50per">
                                                    物流单号:{{ order.row.trackingNumber }}
                                                </div>
                                            </div>
                                            <div>{{ order.row.trackingInfoList[0].context }}</div>
                                            <div>{{ order.row.trackingInfoList[0].ftime }}</div>
                                        </div>
                                        <div v-if="!order.row.trackingInfoList">
                                            暂无物流信息
                                        </div>
                                    </div>
                                </el-popover>
                            </el-table-column>
                            <el-table-column
                                label="同步状态"
                                width="90"
                                v-if="queryFormModel.orderStatus == '30-1' && userData.shop.isPushOrderErp"
                            >
                                {{ order.row.orderSyncErpStatusName }}
                                <div v-if="order.row.orderSyncErpStatus === 100">
                                    {{ order.row.orderSyncErpTime| moment('YYYY-MM-DD HH:mm:ss') }}
                                </div>
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                width="100"
                            >
                                <div
                                    class="table-ctrl"
                                >
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="$router.push('/order/order/orderDetail/' + order.row.id)"
                                    >
                                        查看
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="orderComments(order.row)"
                                    >
                                        平台留言
                                    </el-button>
                                </div>
                            </el-table-column>
                        </el-table>
                        <div class="order-footer">
                            <div class="left">
                                <div class="address">
                                    收件信息：{{ order.row.deliveryConsignee }} <span class="tc-primary">{{ order.row.deliveryPhone }}</span> {{ order.row.deliveryRegionAddress }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="买家留言"
                                >
                                    买家留言：{{ order.row.buyerMsg }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="平台留言"
                                >
                                    平台留言：{{ order.row.orderComments }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="代理商信息"
                                >
                                    下单人：+{{ order.row.buyerCountryCode }}-{{ order.row.buyerMobile }}
                                </div>
                                <div
                                    v-if="order.row.isDistributor"
                                    class="buyer-msg"
                                    title="代理商信息"
                                >
                                    代理商：{{ order.row.distributorLevelInfo }}
                                </div>
                            </div>
                            <div class="right">
                                <div class="inner">
                                    <!--<div
                                        v-if="order.row.isDistributor"
                                        class="distributor">
                                        代理商：
                                        {{ order.row.distributorName }} <span class="tc-primary">+{{ order.row.distributorCountryCode }}-{{ order.row.distributorTel }}</span>
                                    </div>-->
                                    <div
                                        v-if="order.row.extendFieldInfoList && order.row.extendFieldInfoList.length != 0"
                                        class="extra"
                                    >
                                        扩展信息：
                                        <el-popover
                                            trigger="hover"
                                        >
                                            <el-table :data="order.row.extendFieldInfoList">
                                                <el-table-column
                                                    width="150px"
                                                    property="label"
                                                    label="字段名称"
                                                />
                                                <el-table-column
                                                    width="200px"
                                                    property="value"
                                                    label="字段值"
                                                />
                                            </el-table>
                                            <span
                                                slot="reference"
                                                class="tc-blue"
                                            >{{ order.row.extendFieldInfoList[0].label }}:{{ order.row.extendFieldInfoList[0].value }}</span>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    label="单价"
                />
                <el-table-column
                    width="100"
                    label="数量"
                />
                <el-table-column
                    width="100"
                    label="订单金额"
                />
                <el-table-column
                    width="100"
                    label="订单状态"
                />
                <el-table-column
                    width="300"
                    label="物流信息"
                />
                <el-table-column
                    width="90"
                    label="同步状态"
                    v-if="queryFormModel.orderStatus == '30-1' && userData.shop.isPushOrderErp"
                />
                <el-table-column
                    width="100"
                    label="操作"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--平台留言-->
        <el-dialog
            title="平台留言"
            center
            width="600px"
            :visible.sync="orderCommentsDialog.isVisible"
            @closed="onOrderCommentsDialogCancel"
        >
            <el-form
                ref="orderCommentsForm"
                size="small"
                :model="orderCommentsDialog.formModel"
                :rules="orderCommentsDialog.formRules"
            >
                <el-form-item
                    prop="orderComments"
                    label="平台留言"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="orderCommentsDialog.formModel.orderComments"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onOrderCommentsDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onOrderCommentsDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CsOrder',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSnEq: '',
                deliveryPhoneEq: '',
                shipMethod: '1',
                goodsType: '1',
                trackingNumberEq: '',
                buyerMobileEq: '',
            },
            // 表格数据
            tableData: [],
            // 平台留言
            orderCommentsDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderComments: '',
                },
                // 表单校验规则
                formRules: {},
            },
            loading: '',
        };
    },
    computed: {
        tableColumn() {
            return this.$refs.table.columns.length;
        },
    },
    methods: {
        /* -- 方法 -- */
        onSpanMethod() {
            return [1, this.tableColumn];
        },
        onGoodsSpanMethod({ columnIndex, rowIndex }) {
            // console.log({ row, column, rowIndex, columnIndex });
            if (columnIndex < 3) {
                return [1, 1];
            }
            if (!rowIndex) {
                return [100, 1];
            }
            return [0, 0];
        },
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Order.csOrderList(queryData).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: '包装费',
                        value: item.packingAmount,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '保费金额',
                        value: item.goodsPremiumAmount,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
                this.tableData = res.data;
                this.pagination = res.pagination;

                return Promise.resolve(json);
            });
        },
        // 平台留言
        orderComments(row) {
            this.orderCommentsDialog.formModel.id = row.id;
            this.orderCommentsDialog.formModel.orderComments = row.orderComments;
            this.orderCommentsDialog.isVisible = true;
        },
        onOrderCommentsDialogConfirm() {
            const { orderCommentsForm } = this.$refs;
            orderCommentsForm.validate().then(() => {
                this.$api.Ex.Order.editOrderComments({ ...this.orderCommentsDialog.formModel }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.orderCommentsDialog.isVisible = false;
                });
            });
        },
        onOrderCommentsDialogCancel() {
            const { orderCommentsForm } = this.$refs;
            orderCommentsForm.resetFields();
            const { formModel } = this.orderCommentsDialog;
            formModel.id = '';
            formModel.orderComments = '';
            this.orderCommentsDialog.isVisible = false;
        },
        onBatchSyncErpOrder() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择订单',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否确定同步订单！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.Order.batchSyncErpOrder({ ids: ids.join(',') }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
        onBatchSyncErpOrderTrackingInfo() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择订单',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否确定同步订单物流信息！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单物流中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.Order.batchSyncErpOrderTrackingInfo({ ids: ids.join(',') }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
    },
};
</script>

<style lang="scss">
.order-table {
    td.el-table-column--selection {
        vertical-align: top;
    }

    td.cell-no-pa {
        &,
        > .cell {
            padding: 0;
        }
    }

    .table-ctrl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .el-button + .el-button {
            margin: 0;
        }
    }

    .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding $padding;
        background-color: $gray1;

        span + span {
            margin-left: $margin;
        }

        > .supplier {
            flex: 0 0 300px;
        }

        > .shipping-time {
            flex: 0 0 340px;
            text-align: right;
        }
    }

    .order-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: $padding;

        > .left {
            flex: 0.6 0.6 1%;
        }

        > .right {
            flex: 0.4 0.4 1%;
            text-align: right;

            > .inner {
                text-align: left;
                display: inline-block;
            }
        }

        .buyer-msg {
            @include text-line(1);
        }
    }
}
</style>
<style lang="scss" scoped>
.goods-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .image-list-wrap {
        flex: 0 0 auto;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .badge {
            display: inline-block;
            border-radius: 100em;
            background-color: $color-danger;
            color: #fff;
            line-height: 24px;
            min-width: 24px;
            text-align: center;
        }
    }
}
</style>
